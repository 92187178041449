import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { authenticateUrl, userUrl } from '../constants/serviceUrls';
import { HeaderService } from './header.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private headerService: HeaderService
  ) { }

  getUserData() {
    return this.http.get(userUrl.getUserUrl, this.headerService.getHeaders())
  }
}
