import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ContentManagementService } from 'src/app/services/content-management.service';

@Component({
  selector: 'app-idle-pop-up',
  templateUrl: './idle-pop-up.component.html',
  styleUrls: ['./idle-pop-up.component.css']
})
export class IdlePopUpComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<IdlePopUpComponent>,
    private contentManagementService: ContentManagementService
  ) { }

  public StringVariables: any = {};

  ngOnInit(): void {
    this.StringVariables = this.contentManagementService.getContent("EN-US")
  }

  response(responseType) {
    this.dialogRef.close(responseType);
  }

}
